import * as React from "react";
import { useTheme, styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import DropdownIcon from "../../assests/dropdownArrowIcon.svg";
import Tags from "../tags";

const IconStyle = styled("img")(({ theme }) => ({
  paddingRight: 10,
}));

export const CustomSelect = styled(Select)(({ theme, borderLine = true, errorBorders = false}) => ({
  boxShadow: "none",
  borderRadius: 12,
  border: borderLine ? `0.1px solid ${errorBorders ? 'red' : theme.palette.borderColor}` : "none",
  backgroundColor: theme.palette.background.primary,
  height: "40px",
  ".MuiOutlinedInput-notchedOutline": {
    display: "flex",
    border: 0,

    alignItems: "center",
  },
  ".MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  ".MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
}));

export const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
    borderRadius: '0px 0px 16px 16px',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      "&:last-child": {
        borderBottom: 0,
      },
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: theme.spacing(2),
  width: "inherit",
  zIndex: 99999,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",

  borderBottom: `1px solid ${
    theme.palette.mode === "light" ? "#eaecef" : "#30363d"
  }`,
  "& input": {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.secondary,
    padding: 8,
    border: `1px solid ${
      theme.palette.mode === "light" ? "#eaecef" : "#30363d"
    }`,
    fontSize: 14,
  },
}));

function SearchableSelect({
  id,
  name = "",
  ids = "",
  Placeholder = "",
  borderLine = true,
  errorBorders=false,
  fixed = false,
  multiple = false,
  value = 0,
  data = [],
  defaultValue,
  onChange = () => {},
  onBlur = () => {},
  placeholder,
  disablePortal = true,
  errorMessage = null,
  disabled = false,
  withtag = false,
  required= false,
  selectingLimit = null,
  altAxis=false
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const handleChange = React.useCallback(
    (event, newInputValue, reason, isMultiple) => {
      if(reason === "selectOption" && isMultiple && selectingLimit && newInputValue.length > selectingLimit) return;
      onChange(newInputValue);
      if(!isMultiple){ 
        handleClose();
      }
    },
    [onChange]
  );

  const getValue = React.useCallback(() => {
    if (multiple) {
      if (Array.isArray(value) && Array.isArray(data)) {
        if (typeof data?.[0] === "object") {
          return value
            ?.filter((role) =>
              data?.map((item) => item?.[ids])?.includes(role?.[ids])
            )
            ?.map((selected) => selected[name])
            .join(",");
        } else {
          return value
            ?.filter((role) => data?.includes(role?.[ids]))
            ?.map((selected) => selected[name])
            .join(",");
        }
      }
    }
    if (Array.isArray(value)) {
      if (typeof data === "object" && !Array.isArray(data) && data !== null) {
        return value?.find((role) => role[ids] === data[ids])?.[name];
      }
      return value?.find((role) => role[ids] === data)?.[name];
    }
  }, [multiple, value, data, ids, name]);

  const handleClick = (event) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const reorderOptions = React.useCallback((options, selectedValue) => {
    if (!Array.isArray(options)) return options;

    const selectedOptions = options
      .filter((option) =>
        multiple
          ? typeof selectedValue?.[0] === "object"
            ? selectedValue?.some((val) => val[ids] === option[ids])
            : selectedValue?.some((val) => val === option[ids])
          : option[ids] === selectedValue[ids]
      )
      .map((option) => {
        return { ...option, type: "Selected" };
      });

    const unselectedOptions = options
      .filter((option) =>
        multiple
          ? typeof selectedValue?.[0] === "object"
            ? !selectedValue?.some((val) => val[ids] === option[ids])
            : !selectedValue?.some((val) => val === option[ids])
          : option[ids] !== selectedValue[ids]
      )
      .map((option) => {
        return { ...option, type: "All Values" };
      });

    return [...selectedOptions, ...unselectedOptions];
  }, []);

  const memoizedOptions = React.useMemo(() => {
    return multiple &&
      typeof value?.[0] === "object" &&
      !Array.isArray(value?.[0])
      ? reorderOptions(value, data)
      : value;
    // return value;
  }, [value, data, multiple, reorderOptions]);

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const getOptionSelected = (option, selectedValue) => {
    if (ids) {
      return option[ids] === selectedValue[ids];
    }
    return option === selectedValue;
  };

  const open = Boolean(anchorEl);
  const popperId = open ? "github-label" : undefined;

  const tagValue = () => {
    if (Array.isArray(value) && Array.isArray(data)) {
      if (!data.length) return placeholder;
      const mapedvalue = data.map((item) => item[name]);
      return <Tags payload={mapedvalue} style={{marginBottom:"1rem"}}/>;
    } else {
      return getValue();
    }
  };

  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        sx={{
          width: "100%",
          borderWidth: 0,
          cursor: "pointer",
        }}
      >
        <InputLabel
          size="small"
          sx={withtag ? { position: "relative", minHeight: "40px" } : {}}
        >
          <Typography
            variant="body2"
            sx={{
              color: "text.primary",
            }}
          >
            {Placeholder === "value"
              ? withtag
                ? tagValue()
                : getValue()
                ? getValue()
                : placeholder
              : Placeholder}
          </Typography>
        </InputLabel>
        <CustomSelect
          value={""}
          id={id}
          sx={{
            ...(withtag
              ? { width: "100%", height: "100%", position: "absolute" }
              : {}),
            ...(disabled && { backgroundColor: "#f0f0f0", cursor: "not-allowed" })
          }}          
          onClick={handleClick}
          IconComponent={() => <IconStyle src={DropdownIcon} />}
          disabled
          errorBorders={errorBorders}
          borderLine={borderLine}
        />
        {/* <span
          style={{
            color: "red",
            fontSize: "12px",
            fontWeight: 500,
            marginTop: "5px",
            position: "absolute",
            top: "100%",
            left: "0",
          }}
        >
          {errorMessage ? errorMessage : null}
        </span> */}
      </FormControl>
      <StyledPopper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        sx={{ 
          width: anchorEl ? (anchorEl.offsetWidth < 300 ? 300 : anchorEl.offsetWidth) : 'auto', 
          maxWidth: "100%",   
          }}
        placement={fixed ? "top" : "bottom-start"}
        popperOptions={{
          modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 8, // Adjust padding to ensure the popper stays within viewport
              altAxis:altAxis
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom'],
              padding: 8, // Adjust padding to ensure the popper flips within viewport
            },
          },
        ],
          strategy: fixed ? "fixed" : "absolute",
        }}
        disablePortal={disablePortal}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              id={`search-${id}`}
              name={name}
              multiple={multiple}
              onBlur={onBlur}
              sx={{ cursor: "pointer" }}
              defaultValue={defaultValue}
              value={ids ? (multiple ? data : getValue()) : data}
              displayEmpty
              input={<OutlinedInput />}
              open
              onClose={(event, reason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              onChange={(event, value, reason) => handleChange(event, value, reason, multiple)}
              PopperComponent={PopperComponent}
              isOptionEqualToValue={getOptionSelected}
              renderTags={(value, getTagProps, ownerState) => null}
              noOptionsText="No labels"
              groupBy={(option) => option.type}
              renderOption={(props, option, { selected }) => {
                return (
                  <li
                    {...props}
                    style={{
                      backgroundColor:
                        option.type === "Selected" || selected
                          ? theme.palette.background.secondary
                          : "transparent",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      sx={{
                        bgcolor:
                          option.type === "Selected" || selected
                            ? "transparent"
                            : "transparent",
                      }}
                    >
                      {multiple && (
                        <Box
                          id={`checkbox-${option?.[name] ? option[name] : option}`}
                          component={Checkbox}
                          checked={option.type === "Selected" || selected}
                          sx={{
                            width: 17,
                            height: 17,
                            mr: "5px",
                            ml: "-2px",

                            "&.MuiCheckbox-root": {
                              bgcolor: "white",
                            },
                            "&.Mui-checked": {
                              bgcolor: "white",
                              color: "rgb(87,118,231)",
                            },
                            borderRadius: "4px", // Adds some rounding to the corners
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          flexGrow: 1,
                          bgcolor:
                            option.type === "Selected" || selected
                              ? theme.palette.primary
                              : "transparent",
                          "& span": {
                            color: theme.palette.text.primary,
                          },
                        }}
                      >
                        <Typography
                          variant="body2"
                          color={
                            option.type === "Selected" || selected
                              ?theme.palette.text.primary
                              :theme.palette.text.primary
                          }
                        >
                          {option?.[name] ? option[name] : option}
                        </Typography>
                      </Box>
                    </Stack>
                  </li>
                );
              }}
              options={memoizedOptions}
              getOptionLabel={(option) => {
                return option[name] ? option[name] : option;
              }}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Search Actions"
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}

export default React.memo(SearchableSelect);

import { createContext, useState, useEffect, useRef, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { generateHash, getCookie, setCookie } from "./utils";
import { API_ROUTES, apiGet } from "../../configure/apiConfig";
import useSnackbar from "../../component/StatusBadge/Alert";
import config from "../../configure";

const WebsocketContext = createContext({});

// get notifications from DB

export const getNotifications = async (setNotifications, userEmail, roleId, groupId,type) => {
  try {
    const response = await apiGet(API_ROUTES.GET_NOTIFICATIONS_BY_EMAIL, { type,email:userEmail, roleId,groupId });
    const data = response.data.data;
    setNotifications(data || []);
  } catch (error) {
    console.error('Error fetching notifications:', error);
  }
};

export const WebsocketProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [isConnected, setIsConnected] = useState(false);

  const userEmail = useSelector((state) => state?.currentUser?.currentUser?.email)?.toLowerCase() || '';
  const userName = useSelector((state) => state?.currentUser?.currentUser?.name);
  const roleId = useSelector((state) => state?.currentUser?.currentUser?.roleId);
  const groupId = useSelector((state) => state?.currentUser?.currentUser?.groupId);

  const ws = useRef(null);
  const reconnectRef = useRef(false);

  const { ShowSnackbar,SnackbarComponent } = useSnackbar(3000)

  const url = config.websocketURL;

  // Define connectWebSocket outside of useEffect
  const connectWebSocket = useCallback(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) return; // Ensure we only create a new connection if not connected

    let browserId = getCookie('browserId');
    if (!browserId) {
      // Generate a new browserId and store it in a cookie
      browserId = generateHash();
      setCookie('browserId', browserId, 365); // Cookie valid for 1 year
    }

    if ( !userEmail || !roleId ) return;
    
    const socket = new WebSocket(`${url}?browserId=${browserId}&userEmail=${userEmail}&roleId=${roleId}`);
    
    socket.onopen = () => {
      setIsConnected(true);
      reconnectRef.current = false; // Reset reconnect flag
    };
    socket.onclose = (event) => {
      setIsConnected(false);
      if (event.code !== 1000 && !reconnectRef.current) {
        reconnectRef.current = true;
        setTimeout(() => {
          connectWebSocket();
        }, 5000);
      }
    };
    socket.onerror = (error) => console.error("WebSocket error:", error);
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
        setNotifications((prevMessages) => [...prevMessages, data]);
        ShowSnackbar(`${data.content}`,'info')
    };

    ws.current = socket;
  }, [userEmail,roleId]);

  useEffect(() => {
    // Initial WebSocket connection
    connectWebSocket();

    // Cleanup on unmount
    return () => {
      if (ws.current) ws.current.close();
    };
  }, [connectWebSocket]); // Add connectWebSocket to dependency array

  useEffect(() => {
    if ( !userEmail || !roleId || !groupId ) return;
    getNotifications(setNotifications,userEmail,roleId,groupId,'get_unread_notifications');
  }, [userEmail, roleId, groupId]);


  const sendNotification = async (actionName) => {
    if (
      !actionName
    ) {
      console.warn("actionName is empty or invalid.");
      return;
    }

    if (!ws.current) {
      console.warn("WebSocket is not connected.");
      return;
    }

    if (ws.current.readyState !== WebSocket.OPEN) {
      console.warn("WebSocket is not open.");
      return;
    }

    try {
      ws.current.send(
        JSON.stringify({
          action: "sendNotification",
          type: actionName,
          senderName: userName,
        })
      );
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  return (
    <WebsocketContext.Provider
      value={{ notifications, isConnected, sendNotification, setNotifications, SnackbarComponent }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export const useWebsocket = () => useContext(WebsocketContext);

export default WebsocketContext;

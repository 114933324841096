import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { LazyComponents } from "./PrivateRoutes";
import { createElement, useState } from "react";
import { useSelector } from "react-redux";
import { Suspense, lazy } from "react";
import { LoaderComponent } from "./PrivateRoute";
import RouteChangeListener from "../utils/RouteChangeListener";

const LazyLoginForm = lazy(() => import("../screens/login/LoginForm"));
const LazyPermissionDenied = lazy(() => import("../screens/PermissionDenied"));
const LazySideDrawer = lazy(() => import("../component/drawer/sideDrawer"));
const LazyNotFound = lazy(() => import("../screens/NotFound"));

const AuthRouter = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const currentUserRoles = useSelector((state) => state.currentUser?.userRoles);
  const roleId = useSelector((state) => state.currentUser.roleId);
  const routes = useSelector((state) => state.permission.routes);
  const isAuthenticated = useSelector(
    (state) => state.currentUser?.isAuthenticated
  );

  // Create Router Configuration
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <RouteChangeListener />
      ),
      children:[
        {
          path: "/",
          element: (
            <Suspense fallback={<LoaderComponent />}>
             {!roleId || !isAuthenticated  ? (
              <LazyLoginForm drawerController={setIsDrawerOpen} />
            ) : (
              <Navigate to="/dashboard/scopedprojects" replace />  // Redirect to side-drawer if authenticated
            )}
            </Suspense>
          ),
        },
        {
          path: "/permission-denied",
          element: (
            <Suspense fallback={<LoaderComponent />}>
              <LazyPermissionDenied drawerController={setIsDrawerOpen} />
            </Suspense>
          ),
        },
        {
          path: "/",
          element: (
            <Suspense fallback={<LoaderComponent />}>
              <LazySideDrawer />
            </Suspense>
          ),
          children: routes.map(({ id, path, components, access }) => ({
            path,
            element:
              currentUserRoles && isAuthenticated ? (
                  <Suspense fallback={<LoaderComponent />}>
                   {createElement(LazyComponents[components], {
                          drawerController: setIsDrawerOpen,
                        })}
                  </Suspense>
              ) : (
                <Navigate to="/permission-denied" replace />
              ),
          })),
        },
      ]
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<LoaderComponent />}>
          <LazyNotFound />
        </Suspense>
      ),
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default AuthRouter;
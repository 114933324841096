/* eslint-disable jsx-a11y/no-access-key */
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { InnerButton } from "../Buttons/FloatingButtons";


const DynamicCheckBoxs = ({ data, accessKey, title = "", handleCheckboxChange, checkedValues, apiSentKey, idKey }) => {
  const isArrayObject = Array.isArray(data) && data[0] !== null && typeof data[0] === 'object';

  return (
    <Fragment>
      <Stack>
        <Typography
          id="modal-modal-description"
          variant="h5"
          color="text.secondary"
          sx={{ mt: 2 }}
        >{title}</Typography>
      </Stack>
      <Stack sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {
          !data ? <Typography marginBlock={2} variant="h6" color="text.primary" alignItems={"center"}>No data found</Typography> :
          data?.map((item, key) => {
            return (
              <FormControlLabel
                sx={{ marginRight: '12px', width: '33%' }}
                onClick={(e) => e.stopPropagation()}
                label={<Typography variant="h6">{isArrayObject ? item[accessKey] : item}</Typography>}
                control={
                  <Checkbox
                    checked={checkedValues && checkedValues?.some((value) => isArrayObject ? Number(value) === Number(item[idKey]) : value === item )}
                    onChange={(e) => {
                      handleCheckboxChange(accessKey, apiSentKey, idKey, item);
                      e.stopPropagation()
                    }
                    }
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                  />
                }
              />
            )
          })
        }
      </Stack>
    </Fragment>
  )
}

const TableFilterModalChild = ({ title, filterOptions, handleSubmit, filterValues }) => {
  
  const [checkedValues, setCheckedValues] = useState(filterValues);

  const handleCheckboxChange = (_, apiSentKey, idKey, item) => {
    const currentValues = checkedValues[apiSentKey] || [];
    const alreadyPresent = currentValues.includes(Number(item[idKey]) || item);
    const newValues = alreadyPresent ? currentValues.filter((value) => value !== (Number(item[idKey]) || item)) : [...currentValues, Number(item[idKey]) || item];

    setCheckedValues((prev) => ({
      ...prev,
      [apiSentKey]: newValues,
    }))
  }


  return (
    <Stack height={'100%'} width={'100%'} sx={{ overflowX: 'auto' }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        paddingBlock={1}
        zIndex={1000}
        alignContent={"center"}
        position={"sticky"}
        top={0}
        bgcolor={"background.primary"}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          component="h2"
          fontWeight={400}
          lineHeight="24px"
        >
          {title}
        </Typography>

        <InnerButton id='apply-changes-button'
          onClick={() => {
            handleSubmit(checkedValues);
          }}
          sx={{
            backgroundColor: "rgba(28, 28, 28, 0.05)",
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(87, 118, 231, 1)", // Change to desired hover background color
              color: "white", // Change to desired hover text color
            },
          }}
        >Apply Changes</InnerButton>
      </Stack>
      <hr />
        <DynamicCheckBoxs handleCheckboxChange={handleCheckboxChange} data={filterOptions?.departments} checkedValues={checkedValues?.departments} idKey="dept_id" apiSentKey="departments" accessKey="dept_name" title="Departments" />
        <DynamicCheckBoxs handleCheckboxChange={handleCheckboxChange} data={filterOptions?.roles} checkedValues={checkedValues?.roles} idKey="role_id" apiSentKey="roles" accessKey={"role_name"} title="Role" />
        <DynamicCheckBoxs handleCheckboxChange={handleCheckboxChange} data={filterOptions?.bands} checkedValues={checkedValues?.band} apiSentKey="band" title="Band" />
        <DynamicCheckBoxs handleCheckboxChange={handleCheckboxChange} data={filterOptions?.employementTypes} checkedValues={checkedValues?.employeeTypes} apiSentKey="employeeTypes" title="Employement" />
        <DynamicCheckBoxs handleCheckboxChange={handleCheckboxChange} data={filterOptions?.clients} checkedValues={checkedValues?.companies} idKey="company_id" apiSentKey="companies" title="Accounts" accessKey={"company_name"} />
    </Stack>
  )
};

export default TableFilterModalChild;
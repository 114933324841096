import statusConfig from "../../configure/StatusConfig";
import { getParentPath, PageConfig } from "../../configure/PathConfig";
import siteConfig from "../../common/lang/locale/en-US";
import { formatString } from "../dataformatter/Rawformatter";
import { getStatusText } from "../../component/statusbadges/StatusBadge";

export const handleNavigation = ({
	projectId,
	status,
	navigation,
	item = {},
	currentTab,
	inab,
	isRm
}) => {
	let path = "";
	const parentPath = getParentPath()
	
	switch (status) {
		case statusConfig.DRNEW:
		case statusConfig.DRREOPEN:
			path = `${parentPath+PageConfig["DEALS"].OVERVIEWPROJECT}/${formatString(getStatusText(status), "-")}/${currentTab}/${formatString(siteConfig["tab.overview"])}/${projectId}`;
			break;
		case statusConfig.SCOPEDNEW:
		case statusConfig.SCOPEDCMLPT:
			if (isRm) {
				console.log('isRm');
				
				path = `${parentPath+PageConfig['RM'].PRELIMINARY}/${formatString(getStatusText(status), "-")}/${projectId}`;
			}
			else {
				console.log('!isRm');

				path = `${parentPath+PageConfig["PRELIMINARY"].OVERVIEW}/${formatString(getStatusText(status), "-")}/${projectId}`;
			}
			break;
		case statusConfig.SCOPEDINPRG:
			if (isRm) {
				path = `${PageConfig['RM'].REVIEW}/${projectId}`;
			}
			else {
				path = `${parentPath+PageConfig["PRELIMINARY"].REVIEW}/${formatString(getStatusText(status), "-")}/${projectId}`;
			}
			break;
		case statusConfig.DRINPRG:
			path = `${parentPath+PageConfig["DEALS"].DEALREVIEW}/${formatString(getStatusText(status), "-")}/${currentTab}/${formatString(siteConfig["tab.overview"])}/${projectId}`;
			break;
		case statusConfig.DRINREV:
			path = `${parentPath+PageConfig["DEALS"].REVIEWANDAPPROVAL}/${formatString(getStatusText(status), "-")}/${projectId}`;
			break;
		case statusConfig.DRAPVD:
			path = `${parentPath+PageConfig["DEALS"].REVIEWANDAPPROVAL}/${formatString(getStatusText(status), "-")}/${projectId}`;
			break;
		case statusConfig.DRPEND:
			path = `${parentPath+PageConfig["DEALS"].LEGALQUESTIONNAIRE}/${formatString(getStatusText(status), "-")}/${projectId}`;
			break;
		case statusConfig.LEGNEW:
			path = `${parentPath+PageConfig["LEGAL"].OVERVIEWPROJECT}/${formatString(getStatusText(status, "contain"), "-")}/${currentTab}/${formatString(siteConfig["tab.overview"])}/${projectId}`;
			break;
		case statusConfig.LEGINPRG:
			path = `${parentPath+PageConfig["LEGAL"].SOWGENERATE}/${formatString(getStatusText(status), "-")}/${currentTab}/${formatString(siteConfig["tab-sow-info"])}/${projectId}`;
			break;
		case statusConfig.LEGREV:
		case statusConfig.LEGCUSTREV:
		case statusConfig.LEGCUSTREJ:
		case statusConfig.LEGCUSTAPVD:
			path = `${parentPath+PageConfig["LEGAL"].FINALREVIEW}/${formatString(getStatusText(status), "-")}/${currentTab}/${projectId}`;
			break;
		case statusConfig.LEGVLDT:
			path = `${parentPath+PageConfig["LEGAL"].SOWUPLOAD}/${formatString(getStatusText(status), "-")}/${currentTab}/${projectId}`;
			break;
		case statusConfig.RMNEW:
			if (inab) {
				path = `${parentPath+PageConfig["RM"].INABALLOCATON}/${projectId}`;
			} else {
				path = `${parentPath+PageConfig["RM"].RESOURCEALLOCTION}/${formatString(getStatusText(status), "-")}/${projectId}`;
			}
			break;
		case statusConfig.RMINPRG:
			if (inab) {
				path = `${parentPath+PageConfig["RM"].INABRESOURCEALLOCTIONFACT}/${formatString(getStatusText(status), "-")}/${projectId}`;
			} else {
				path = `${parentPath+PageConfig["RM"].RESOURCEALLOCTIONFACT}/${formatString(getStatusText(status), "-")}/${projectId}`;
			}
			break;
		case statusConfig.RMREOPEN:
			if (inab) {
				path = `${parentPath+PageConfig["RM"].INABRESOURCEALLOCTIONFACT}/${formatString(getStatusText(status), "-")}/${projectId}`;
			} else {
				path = `${parentPath+PageConfig["RM"].RESOURCEALLOCTIONFACT}/${formatString(getStatusText(status), "-")}/${projectId}`;
			}
			break;
		case statusConfig.RMREV:
		case statusConfig.RMAPVD:
			if (inab) {
				path = `${parentPath+PageConfig["RM"].INABRESOURCEALLOCTIONFACTREVIEW}/${formatString(getStatusText(status), "-")}/${projectId}`;
			} else {
				path = `${parentPath+PageConfig["RM"].RESOURCEALLOCTIONFACTREVIEW}/${formatString(getStatusText(status), "-")}/${projectId}`;
			}

			break;

		case statusConfig.DRCMPLT:
			path = `${parentPath+PageConfig["DEALS"].REVIEWANDAPPROVAL}/${formatString(getStatusText(status), "-")}/${projectId}`;
			break;
		case statusConfig.LEGCMPLT:
			path = `${parentPath+PageConfig["LEGAL"].FINALREVIEW}/${formatString(getStatusText(status), "-")}/${currentTab}/${projectId}`;
			break;
		case statusConfig.RMCMPLT:
			if (inab) {
				path = `${parentPath+PageConfig["RM"].INABRESOURCEALLOCTIONFACTREVIEW}/${formatString(getStatusText(status), "-")}/${projectId}`;
			} else {
				path = `${parentPath+PageConfig["RM"].RESOURCEALLOCTIONFACTREVIEW}/${formatString(getStatusText(status), "-")}/${projectId}`;
			}
			break;
		case statusConfig.INABNEW:
			path = `${PageConfig["INAB"].REVIEWANDAPPROVAL}/${formatString(getStatusText(status), "-")}/${projectId}`;
			break;
		case statusConfig.INABAPRV:
			path = `${PageConfig["INAB"].SUBMITFORRM}/${formatString(getStatusText(status), "-")}/${projectId}`;
			break;
		case statusConfig.INABREJ:
		case statusConfig.INABCMPLT:
			path = `${PageConfig["INAB"].SUBMITFORRM}/${formatString(getStatusText(status), "-")}/${projectId}`;
			break;
		default:
			path = PageConfig.DASHBOARD;
	}

	navigation(path);
};
export const handleRmNavigation = ({
	projectId,
	status,
	company,
	navigation,
	item = {},
	currentTab,
}) => {
	let path = "";
	if (company !== "Wavicle Data Solutions") {
		path = `${PageConfig["RM"].PRoJECTSOVERVIEW}/${formatString(getStatusText(status), "-")}/${currentTab}/${formatString(siteConfig["tab.overview"])}/${projectId}`;
	}
	else {
		path = `${PageConfig["RM"].INABPROJECTSOVERVIEW}/${formatString(getStatusText(status), "-")}/${projectId}`;
	}
	navigation(path);
};

import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ShakingIcon = ({ children }) => {
  const { ref, inView } = useInView();

  const iconVariants = {
    initial: {
      scale: 1,
      rotate: 0,
    },
    shaking: {
      scale: 1.2,
      rotate: [-5, 5,-5,5],
      transition: {
        duration: 0.5,
        repeat: 5,
        repeatType: "reverse",
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="initial"
      animate={inView ? "shaking" : "initial"}
      variants={iconVariants}
      onAnimationComplete={() => {
        if (!inView) {
          return { scale: 1, rotate: 0 };
        }
      }}
    >
      {children}
    </motion.div>
  );
};

export default ShakingIcon;


const {
	DASHBOARD_PATH,
	LEGAL_DASHBOARD_PATH,
	DEALS_DASHBOARD_PATH,
	PRELIMINARY_DASHBOARD_PATH,
	RM_PATH,
	INAB_PATH,
} = {
	DASHBOARD_PATH: "/dashboard",
	LEGAL_DASHBOARD_PATH: "/legal-dashboard",
	DEALS_DASHBOARD_PATH: "/deal-review-dashboard",
	PRELIMINARY_DASHBOARD_PATH: "/scoped-dashboard",
	RM_PATH: "/rm",
	INAB_PATH: "/inab",
};

const PageConfig = {
	DASHBOARD: DASHBOARD_PATH,
	LEGAL: {
		LEGAL_DASHBOARD: LEGAL_DASHBOARD_PATH,
		OVERVIEWPROJECT: "/legal-over-view",
		SOWGENERATE: "/sow-generation",
		SOWUPLOAD: "/sow-upload",
		LEGALDOWNLOAD: "/sow-download",
		FINALREVIEW: "/sow-review",
		LEGALQUESTIONNAIRE: "/deal-review-dashboard/legal-questionnaire",
	},
	DEALS: {
		DEALREVIEWDASHBOARD: DEALS_DASHBOARD_PATH,
		OVERVIEWPROJECT: "/over-view-project",
		DEALREVIEW: "/deal-review",
		REVIEWANDAPPROVAL: "/review-and-approval",
		ALLDEALREVIEW: "/deal-review-dashboard/all-deal-review",
		LEGALQUESTIONNAIRE: "/legal-questionnaire",
	},
	DEALREVIEW: {
		OVERVIEWPROJECT: "/over-view-project",
		FINALREVIEW: "/final-review",
	},
	RM: {
		RMDASHBOARD: `${RM_PATH}/rm-dashboard`,
		PEOPLELANDING: `${RM_PATH}/peoplelanding`,
		ADDEMPLOYEE: `${RM_PATH}/peoplelanding/add-employee`,
		PROFILE: `${RM_PATH}/peoplelanding/profile`,
		PROJECTS: `${RM_PATH}/projects`,
		PRoJECTSOVERVIEW: `${RM_PATH}/projects/project-details`,
		INABPROJECTSOVERVIEW: `${RM_PATH}/projects/inab-details`,
		PROJECTHOME: `${RM_PATH}/resource-allocation-dashboard`,
		RESOURCEALLOCTION: `/resource-allocation-dashboard/resource-allocation`,
		INABALLOCATON: `/inab-resource-allocation`,
		RESOURCEALLOCTIONFACT: `/resource-allocation/resource-allocation-fact`,
		INABRESOURCEALLOCTIONFACT: `/inab-resource-allocation/resource-allocation-fact`,
		RESOURCEALLOCTIONFACTREVIEW: `/resource-allocation/resource-allocation-fact/review`,
		INABRESOURCEALLOCTIONFACTREVIEW: `/inab-resource-allocation/resource-allocation-fact/review`,
		PRELIMINARY: `/pre-staffing-overview`,
		REVIEW: `${RM_PATH}/preliminarystaffingreview`,
	},
	PRELIMINARY: {
		DASHBOARD: PRELIMINARY_DASHBOARD_PATH,
		OVERVIEW: "/scoped-overview",
		REVIEW: "/scoped-review",
	},
	ADMIN: {
		DASHBOARD: "/admin",
		RBAC: "/admin/annotation",
		RBACRATECARD: "/admin/ratecard"
	},
	INAB: {
		DASHBOARD: INAB_PATH,
		NEWSUBMISSION: INAB_PATH+"/new-submission",
		REVIEWANDAPPROVAL: INAB_PATH+"/review-and-approval",
		SUBMITFORRM: INAB_PATH+"/submit-for-rm",
	},
	SETTINGS: {
		NOTIFICATION: "/settings/notification",
	},

	// Add more pages as needed
	SOW_DOWNLOAD: "sow-download",
	LEGAL_DASHBOARD: "legal-dashboard",
	SOW_REVIEW: "sow-review",
	SOW_GENERATION: "sow-generation",

	LABEL_SOW_DOWNLOAD: "SOW-download",
	LABEL_DASHBOARD: "dashboard",
	LABEL_SOW_GENERATION: "SOW-generation",
	LABEL_SOW_REVIEW: "SOW_REVIEW",

	BUTTON_REVIEW: "Submit for Review",
	BUTTON_CUST_REV: "Submit for Customer Review",
	BUTTON_RM: "Submit for Resource Management",
	BUTTON_DR: "Submit for Deal Review",

	//Legal Details Slice Page
	FETCH_LEGAL: "legalDetails/fetchLegalDetails",

	ERROR_SNACKBAR: "error",
	SUCCESS_SNACKBAR: "success",
};

const getParentPath = () => {
	const url = new URL(window.location.pathname, window.location.origin);
	const parentPath = "/"+url.pathname.split("/").slice(1, 2).join("");
	return parentPath;
};


export {
	PageConfig, DASHBOARD_PATH, LEGAL_DASHBOARD_PATH, DEALS_DASHBOARD_PATH,
	PRELIMINARY_DASHBOARD_PATH, RM_PATH, INAB_PATH, getParentPath
};
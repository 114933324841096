import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import NoDataIcon from "../assests/nodata.svg"
// Styled component for the container
const CenteredContainer = styled(Grid)({
  display: 'block',
//   flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width:"100%"
});

// Styled component for the image container
const ImageContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '10px',
});

// Styled component for the image
const StyledImage = styled('img')({
  width: '30%', // Adjust as needed
  height: '30%', // Adjust as needed
});

// Styled component for the text below the image
const NoDataText = styled('p')({
  textAlign: 'center',
  fontSize: '14px', // Adjust as needed
});

const Nodata = () => {
  return (
    <CenteredContainer container>
      <Grid item>
        <div>
          {/* Image container */}
          <ImageContainer>
            {/* Image */}
            <StyledImage src={NoDataIcon} alt="No data" />
          </ImageContainer>
          
          {/* Text below the image */}
          <NoDataText>No data to show</NoDataText>
        </div>
      </Grid>
    </CenteredContainer>
  );
};

export default Nodata;
